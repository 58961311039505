<template>
    <el-main>
        <el-space wrap :size="20">
            <el-card>
                <div class='app-card center'>
                    <el-space style='height: 100%;'>
                        <el-button icon='el-icon-plus' @click='addOne'></el-button>
                    </el-space>
                </div>
            </el-card>

            <el-card v-for="(item) in apps" :key="item.appkey">
                <div class='app-card'>
                    <el-image :src="item.icon"></el-image>
                    <el-tag v-if='item.status == 0' class='app-tag' type="warning">未审核</el-tag>
                    <el-tag v-if='item.status == 1' class='app-tag' type="success">已上线</el-tag>
                    <el-tag v-if='item.status == 2' class='app-tag' type="info">未上线</el-tag>
                    <span class='app-name'>{{item.name}}</span>
                    <el-tooltip :content='item.description || "无"' :visible-arrow=' false' effect="light"
                        placement="top-start" :offset='offset'>
                        <span class=' app-line'>{{item.description || '无'}}</span>
                    </el-tooltip>

                    <el-popover placement="top-start" :width="340" trigger="hover">
                        <el-row>
                            <el-tag type='info'>应用名称: </el-tag><span class='tooltip'>{{item.name}}</span>
                        </el-row>
                        <el-row>
                            <el-tag type='info'>跳转Url: </el-tag><span class='tooltip'>{{item.page}}</span>
                        </el-row>
                        <el-row>
                            <el-tag type='info'>描述: </el-tag><span class='tooltip'>{{item.description || '无'}}</span>
                        </el-row>
                        <el-row>
                            <el-tag type='info'>Appkey: </el-tag><span class='tooltip'>{{item.appkey}}</span>
                        </el-row>
                        <el-row>
                            <el-tag type='info'>Secret: </el-tag><span class='tooltip'>{{item.secret}}</span>
                        </el-row>
                        <el-row>
                            <el-tag type='info'>应用状态: </el-tag><span class='tooltip'>{{fstatus(item.status)}}</span>
                        </el-row>
                        <el-row>
                            <el-tag type='info'>创建: </el-tag><span>{{item.base.creatorName}} &nbsp;</span><time
                                class="time">{{ item.base.created }}</time>
                        </el-row>
                        <el-row>
                            <el-tag type='info'>更新: </el-tag><span>{{item.base.updaterName}} &nbsp;</span><time
                                class="time">{{ item.base.updated }}</time>
                        </el-row>
                        <template #reference>
                            <el-button icon='el-icon-view' class='app-view' type='text'> </el-button>
                        </template>
                    </el-popover>
                    <div class="app_bottom">
                        <el-tooltip v-if='item.status == 1' content="管理应用" :visible-arrow='false' effect="light"
                            placement="top-start" :offset='offset'>
                            <el-button type="text" icon='el-icon-setting' class="button" @click="process('set', item)">
                            </el-button>
                        </el-tooltip>

                        <el-tooltip v-if='item.status != 2' content="编辑" :visible-arrow='false' effect="light"
                            placement="top-start" :offset='offset'>
                            <el-button type="text" icon='el-icon-edit' class="button" @click="process('edit', item)">
                            </el-button>
                        </el-tooltip>

                        <el-tooltip v-if='item.status == 1' content="重置Secret" :visible-arrow='false' effect="light"
                            placement="top-start" :offset='offset'>
                            <el-button type="text" icon='el-icon-refresh' class="button"
                                @click="process('flush', item)">
                            </el-button>
                        </el-tooltip>

                        <el-tooltip v-if='item.status == 1' content="下线" :visible-arrow='false' effect="light"
                            placement="top-start" :offset='offset'>
                            <el-button icon='el-icon-download' type="text" class="button"
                                @click="process('offline', item)">
                            </el-button>
                        </el-tooltip>

                        <el-tooltip v-if='item.status == 2' content="上线" :visible-arrow='false' effect="light"
                            placement="top-start" :offset='offset'>
                            <el-button icon='el-icon-upload2' type="text" class="button"
                                @click="process('online', item)">
                            </el-button>
                        </el-tooltip>
                    </div>
                </div>
            </el-card>
        </el-space>

        <el-dialog v-model="panelVisible">
            <el-form label-position="left" size="mini" ref="appforms" :rules='addRules' status-icon label-width="80px"
                :model="appform">
                <el-form-item label="appkey">
                    <el-input disabled v-model="appform.appkey"></el-input>
                </el-form-item>
                <el-form-item label="应用名" prop='name'>
                    <el-input clearable v-model="appform.name"></el-input>
                </el-form-item>
                <el-form-item label="应用类型">
                    <el-select v-model="appform.type" placeholder=" 请选择应用类型">
                        <el-option v-for="item in apptypes" :key="item.id" :label="item.name" :value="item.id" />
                    </el-select>

                </el-form-item>
                <el-form-item label="跳转Url" prop='page'>
                    <el-input clearable v-model="appform.page"></el-input>
                </el-form-item>
                <el-form-item label="应用描述">
                    <el-input clearable v-model="appform.description"></el-input>
                </el-form-item>
                <el-form-item label="图标" prop='icon'>
                    <el-upload class="avatar-uploader" action="/api/v1/file/simple/upload" :show-file-list="false"
                        :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                        <div class="el-upload__tip">只能上传jpg/png文件，且不超过100kb</div>
                        <img v-if="appform.icon" :src="appform.icon" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item>
                    <el-button size="small" @click="panelVisible = false">取消</el-button>
                    <el-button type="primary" size="small" @click="submitForm">立即创建</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>


    </el-main>
</template>
<style>
    .el-card__body {
        padding: 12px;
    }

    .app-card {
        font-size: 16px;
        width: 240px;
        height: 160px;
        color: #8e8e8e;
        text-align: left;
        position: relative;
    }

    .center {
        text-align: center;
    }

    .app-card .el-image {
        width: 72px;
        height: 72px;
    }

    .app-card span {
        line-height: 16px;
        display: block;
        padding: 4px 0;
        word-break: keep-all;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        -icab-text-overflow: ellipsis;
        -khtml-text-overflow: ellipsis;
        -moz-text-overflow: ellipsis;
        -webkit-text-overflow: ellipsis;
    }

    .app-card .app-tag {
        position: absolute;
        top: 8px;
        right: 34px;
        width: 42px;
        height: 24px;
        text-align: center;
    }

    .app-card .app-view {
        position: absolute;
        top: 0;
        right: 0;
        text-align: center;
    }

    .app-card .app-name {
        font-size: 20px;
        font-weight: 500;
        color: #606266;
        padding: 16px 0 10px 0;
    }

    .app-card .app-line {}

    .app-card .app_bottom {
        line-height: 16px;
        position: absolute;
        top: 32px;
        right: 0;
    }


    .el-popover .el-row {
        line-height: 30px;
        margin: 3px;
    }

    .el-popover .el-row .tooltip {
        width: 260px;
        word-break: keep-all;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        -icab-text-overflow: ellipsis;
        -khtml-text-overflow: ellipsis;
        -moz-text-overflow: ellipsis;
        -webkit-text-overflow: ellipsis;
    }

    .el-popover .el-row .el-tag {
        width: 68px;
        margin-right: 3px;
    }

    .avatar-uploader .el-upload {
        cursor: pointer;
        position: relative;
        overflow: hidden;
        text-align: left;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
    }

    .avatar {
        height: 48px;
        display: block;
    }
</style>
<script>
    import { ref, reactive } from 'vue'
    import router from '@/router/index.js'
    import { setAllByType, appSearch, appReset, appAsk, appEdit, appEnable, appDisable } from '@/utils/api'
    import { setTypes } from '@/utils/constant'
    import { ElMessage } from 'element-plus'

    export default {
        setup: function () {
            // 应用列表
            let apps = ref()
            // 编辑新增菜单
            let panelVisible = ref(false)
            // 编辑新增对象
            let appform = ref({
                name: '',
                page: '',
                icon: '',
                description: '',
                appkey: '',
                type: 0,
            })
            let apptypes = ref()
            // 新增
            let addOne = function (item) {
                if (item) {
                    appform.value = {
                        name: item.name,
                        page: item.page,
                        icon: item.icon,
                        description: item.description,
                        appkey: item.appkey,
                        type: item.type,
                    }
                } else {
                    appform.value = {
                        name: '',
                        page: '',
                        icon: '',
                        description: '',
                        appkey: '',
                        type: 0,
                    }
                }
                panelVisible.value = true
            }
            // 查询
            let findMyList = function () {
                appSearch().then(res => {
                    apps.value = res.data
                })
            }
            // 按钮操作
            let process = function (key, item) {
                if (item && item.appkey) {
                    if (key == 'edit') {
                        addOne(item)
                    }
                    if (key == 'flush') {
                        appReset(item.appkey).then((res) => {
                            findMyList()
                        })
                    }
                    if (key == 'online') {
                        appEnable(item.appkey).then((res) => {
                            findMyList()
                        })
                    }
                    if (key == 'offline') {
                        appDisable(item.appkey).then((res) => {
                            findMyList()
                        })
                    }
                    if (key == 'set') {
                        router.push({ path: '/pages/mg/' + item.appkey + '/owner' })
                    }
                }
            }
            let selectSetAllTypes = function () {
                setAllByType(setTypes.app).then(res => {
                    let ats = []
                    res.data.forEach(at => {
                        ats.push({
                            id: at.id,
                            name: at.name,
                        })
                    });
                    apptypes.value = ats
                })
            }

            let handleAvatarSuccess = function (res, file) {
                appform.value.icon = res.data
            }
            let beforeAvatarUpload = function (file) {

                const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
                const isLt2M = file.size / 1024 < 100;
                if (!isJPG) {
                    ElMessage.error('上传头像图片只能是 JPG/PNG 格式!');
                }
                if (!isLt2M) {
                    ElMessage.error('上传头像图片大小不能超过 100KB!');
                }
                return isJPG && isLt2M;
            }

            return {
                apps,
                apptypes,
                panelVisible,
                appform,
                addOne,
                findMyList,
                process,
                offset: -10,
                fstatus: function (status) {
                    return status == 0 ? '已提交' : (status == 1 ? '已上线' : '已停用')
                },
                selectSetAllTypes,
                addRules: {
                    name: [
                        { required: true, message: '请输入应用名称', trigger: 'change' },
                    ],
                    page: [
                        { required: true, message: '请输入默认跳转地址', trigger: 'change' },
                    ],
                    icon: [
                        { required: true, message: '请输入网络图标地址', trigger: 'change' },
                    ],
                },
                beforeAvatarUpload,
                handleAvatarSuccess,
            }
        },
        methods: {
            submitForm: function () {
                let _this = this
                this.$refs['appforms'].validate((valid) => {
                    if (valid) {
                        let appkey = _this.appform.appkey
                        let param = {
                            name: _this.appform.name,
                            page: _this.appform.page,
                            type: _this.appform.type,
                            icon: _this.appform.icon,
                            description: _this.appform.description,
                        }
                        if (appkey) {
                            appEdit(appkey, param).then(res => {
                                _this.findMyList()
                            })

                        } else {
                            appAsk(param).then(res => {
                                _this.findMyList()
                            })
                        }
                        _this.panelVisible = false
                    } else {
                        return false;
                    }
                });
            },
        },
        mounted: function () {
            this.findMyList()
            this.selectSetAllTypes()
        }
    }
</script>